
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'

@Options({
  components: {
    PagePlugin,
    CustomDialog
  }
})
export default class apply_confirm extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
  tabel1_all_data = []
  tabel1_total = 0
  tabel1_search_data = {
    patent_user_name: '',
    name: '',
    number: ''
  }
  rule_tabel1_search_data = {}
  tabel1_detail_dialog = {
    open: false,
    title: '详情'
  }
  tabel1_detail_data = {
    person_info: {
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      name: '',
      type: 0,
      industry_involved: [],
      related_industry: [],
      patentee: '',
      digest: '',
      claim_rights: '',
      specification: '',
      result_form: '',
      others_msg: [] as any,
      digest_file: [] as any,
      claim_rights_file: [] as any,
      specification_file: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: '',
      max_price: 0,
      min_price: 0
    },
    self_info: []
  }

  tabel1_edit_dialog = {
    open: false,
    title: '审核'
  }

  tabel1_edit_data = {
    id: 0,
    status_: 0,
    return_reason: '',
    _return_reason: ''
  }

  rule_tabel1_edit_data = {}

  getIndustry: any[] = []
  getIndustry2: any[] = []
  WeightList: any[] = []
  status_77 = new Map<any, string>([
    [1, '待审核'],
    [2, '通过'],
    [3, '拒绝']
  ])
  status__78 = new Map<any, string>([
    [2, '通过'],
    [3, '拒绝']
  ])
  type_93 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  type_654 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  status_657 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员确认'],
    [2, '待指派组长'],
    [3, '待分配专家'],
    [4, '待专家评'],
    [5, '待组长审核'],
    [6, '审核通过'],
    [7, '审核不通过']
  ])
  type_676 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])

  tabel1_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      patent_user_name: this.tabel1_search_data.patent_user_name,
      name: this.tabel1_search_data.name,
      number: this.tabel1_search_data.number,
      patent_type: 1
    }
    Api.http_apply_confirmtabel10(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tabel1_all_data = res.data.data
        this.tabel1_total = res.data.total
      }
    })
  }
  tabel1_detail_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)

    Api.http_apply_confirmtabel1get4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        console.warn('专利详情 >> ', res.data)
        this.tabel1_detail_data.person_info = res.data.person_info
        this.tabel1_detail_data.base_info = res.data.base_info
        this.tabel1_detail_data.supplement = res.data.supplement
        this.tabel1_detail_data.conversion_situation
          = res.data.conversion_situation
        if (Utils.isJSON(this.tabel1_detail_data.conversion_situation.expect)) {
          this.tabel1_detail_data.conversion_situation.expect = JSON.parse(
            this.tabel1_detail_data.conversion_situation.expect
          ).join(',')
        }
        if (Utils.isJSON(this.tabel1_detail_data.base_info.others_msg)) {
          this.tabel1_detail_data.base_info.others_msg = JSON.parse(
            this.tabel1_detail_data.base_info.others_msg
          )
        }
        if (Utils.isJSON(this.tabel1_detail_data.base_info.claim_rights_file)) {
          this.tabel1_detail_data.base_info.claim_rights_file = JSON.parse(
            this.tabel1_detail_data.base_info.claim_rights_file
          )
        }
        if (Utils.isJSON(this.tabel1_detail_data.base_info.digest_file)) {
          this.tabel1_detail_data.base_info.digest_file = JSON.parse(
            this.tabel1_detail_data.base_info.digest_file
          )
        }
        if (
          Utils.isJSON(this.tabel1_detail_data.base_info.specification_file)
        ) {
          this.tabel1_detail_data.base_info.specification_file = JSON.parse(
            this.tabel1_detail_data.base_info.specification_file
          )
        }
        this.tabel1_detail_data.self_info = res.data.self_info
        this.tabel1_detail_dialog.open = true
        this.setDialogName(this.tabel1_detail_dialog.title)
      }
    })
  }
  tabel1_detail_cancle() {
    this.tabel1_detail_dialog.open = false
    this.removeDialogName()
  }
  tabel1_detail_ok() {
    this.tabel1_detail_dialog.open = false
    this.removeDialogName()
  }
  tabel1_edit_init(row) {
    Api.http_apply_confirmtabel1get5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tabel1_edit_data.id = row.id
        this.tabel1_edit_data.status_ = res.data.status_
        this.tabel1_edit_data._return_reason = res.data.return_reason
        this.tabel1_edit_dialog.open = true
      }
    })
  }
  tabel1_edit_cancle() {
    this.tabel1_edit_dialog.open = false
  }
  tabel1_edit_ok() {
    this.$refs.ref_tabel1_edit_data.validate((valid) => {
      if (valid) {
        if (
          this.tabel1_edit_data.status_ === 3
          && !this.tabel1_edit_data.return_reason
        ) { return this.$message.error('请填写拒绝理由') }
        const postData = {
          id: this.tabel1_edit_data.id,
          status_: this.tabel1_edit_data.status_,
          return_reason: this.tabel1_edit_data._return_reason
            ? this.tabel1_edit_data.return_reason
              + ';'
              + this.$store.state.userInfo.name
              + ':'
              + this.tabel1_edit_data._return_reason
            : this.$store.state.userInfo.name
              + ':'
              + this.tabel1_edit_data.return_reason
        }
        Api.http_apply_confirmtabel15(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tabel1_edit_dialog.open = false
            this.tabel1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tabel1_init() {
    this.tabel1_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
  }
  get_getIndustry(id = -1) {
    Api.http_apply_confirmtabel1getIndustry0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.getIndustry = res.data.data
      }
    })
  }
  get_getIndustry2(id = -1) {
    Api.http_apply_confirmtabel1getIndustry20({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.getIndustry2 = res.data.data
      }
    })
  }
  get_WeightList(id = -1) {
    Api.http_apply_confirmtabel1WeightList0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.WeightList = res.data.data
      }
    })
  }
  created() {
    this.tabel1_init()
  }
}
